import {autoUpdate, computePosition, flip, offset, shift} from '@floating-ui/react-dom';
import {clsx} from 'clsx';
import useFocusLock from 'focus-layers';
import React from 'react';
import * as PopoutActionCreators from '~/actions/PopoutActionCreators';
import type {Popout} from '~/components/uikit/Popout';
import styles from '~/components/uikit/Popout/Popout.module.css';
import PopoutStore from '~/stores/PopoutStore';

type PopoutItemProps = Omit<Popout, 'key'> & {
	popoutKey: string;
};

const PopoutItem: React.FC<PopoutItemProps> = ({
	popoutKey,
	render,
	position,
	target,
	zIndexBoost,
	shouldAutoUpdate = true,
	offsetMainAxis = 8,
	offsetCrossAxis = 0,
	animationType = 'smooth',
	containerClass,
	onCloseRequest,
	onClose,
	returnFocusRef,
}) => {
	const popoutRef = React.useRef<HTMLDivElement>(null);
	const lastValidPosition = React.useRef<{x: number; y: number} | null>(null);
	const cleanupRef = React.useRef<(() => void) | null>(null);
	const [isVisible, setIsVisible] = React.useState(false);
	const [isPositioned, setIsPositioned] = React.useState(false);

	useFocusLock(popoutRef);

	React.useEffect(() => {
		if (!target || !popoutRef.current || !document.contains(target)) {
			return;
		}

		const updatePosition = async () => {
			try {
				const {x, y} = await computePosition(target, popoutRef.current!, {
					placement: position,
					middleware: [offset({mainAxis: offsetMainAxis, crossAxis: offsetCrossAxis}), flip(), shift({padding: 6})],
				});

				if (!popoutRef.current) return;

				Object.assign(popoutRef.current.style, {
					left: `${x}px`,
					top: `${y}px`,
				});

				lastValidPosition.current = {x, y};
				setIsPositioned(true);

				requestAnimationFrame(() => {
					setIsVisible(true);
				});
			} catch (error) {
				console.error('Error positioning popout:', error);
			}
		};

		updatePosition();

		if (shouldAutoUpdate && document.contains(target)) {
			cleanupRef.current = autoUpdate(target, popoutRef.current, updatePosition);
		}

		return () => {
			cleanupRef.current?.();
			cleanupRef.current = null;
			setIsPositioned(false);
			setIsVisible(false);
		};
	}, [target, position, shouldAutoUpdate, offsetMainAxis, offsetCrossAxis]);

	React.useEffect(() => {
		const handleOutsideClick = (event: MouseEvent | KeyboardEvent) => {
			if (event instanceof KeyboardEvent && event.key !== 'Escape') return;

			const targetElement = event.target as Node;
			if (popoutRef.current && !popoutRef.current.contains(targetElement)) {
				if (onCloseRequest && !onCloseRequest(event)) return;

				setIsVisible(false);
				const closeDuration = animationType === 'smooth' ? 250 : 0;

				setTimeout(() => {
					onClose?.();
					PopoutActionCreators.close(popoutKey);
				}, closeDuration);

				if (returnFocusRef?.current) {
					returnFocusRef.current.focus();
				}
			}
		};

		document.addEventListener('click', handleOutsideClick, true);
		document.addEventListener('keydown', handleOutsideClick, true);

		return () => {
			document.removeEventListener('click', handleOutsideClick, true);
			document.removeEventListener('keydown', handleOutsideClick, true);
		};
	}, [popoutKey, onCloseRequest, onClose, returnFocusRef, animationType]);

	const transitionStyles = React.useMemo(() => {
		const duration = animationType === 'smooth' ? '250ms' : '0ms';
		return {
			opacity: isVisible ? 1 : 0,
			transform: isVisible ? 'scale(1)' : 'scale(0.98)',
			transition: `opacity ${duration} ease-in-out, transform ${duration} ease-in-out`,
			pointerEvents: isPositioned ? ('auto' as const) : ('none' as const),
			visibility: isPositioned ? ('visible' as const) : ('hidden' as const),
		};
	}, [isVisible, isPositioned, animationType]);

	const handleClose = React.useCallback(() => {
		setIsVisible(false);
		const closeDuration = animationType === 'smooth' ? 250 : 0;

		setTimeout(() => {
			onClose?.();
			PopoutActionCreators.close(popoutKey);
		}, closeDuration);
	}, [animationType, onClose, popoutKey]);

	return (
		<div
			ref={popoutRef}
			className={clsx(styles.popout, containerClass)}
			aria-modal
			role="dialog"
			tabIndex={-1}
			style={{
				position: 'fixed',
				zIndex: zIndexBoost != null ? 1000 + zIndexBoost : undefined,
				...transitionStyles,
			}}
		>
			{render({
				popoutKey,
				onClose: handleClose,
			})}
		</div>
	);
};

export const Popouts: React.FC = () => {
	const {popouts} = PopoutStore.useStore();
	return (
		<div className={styles.popouts}>
			{Object.values(popouts).map((popout) => (
				<PopoutItem {...popout} key={popout.key} popoutKey={popout.key.toString()} />
			))}
		</div>
	);
};
