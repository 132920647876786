import {AnimatePresence} from 'motion/react';
import React from 'react';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import {Toast} from '~/components/uikit/Toast/Toast';
import ToastStore from '~/stores/ToastStore';

export const Toasts = () => {
	const {currentToast} = ToastStore.useStore();

	const closeToast = React.useCallback((id: string) => {
		ToastActionCreators.destroyToast(id);
	}, []);

	return (
		<div className="fixed top-6 left-1/2 -translate-x-1/2 z-[4999] pointer-events-none">
			<AnimatePresence mode="wait">
				{currentToast && (
					<div key={currentToast.id} className="pointer-events-auto">
						<Toast id={currentToast.id} closeToast={closeToast} {...currentToast.data} />
					</div>
				)}
			</AnimatePresence>
		</div>
	);
};
