export const SVGMasks = () => (
	<svg
		viewBox="0 0 1 1"
		aria-hidden={true}
		style={{
			position: 'absolute',
			pointerEvents: 'none',
			top: '-1px',
			left: '-1px',
			width: 1,
			height: 1,
		}}
	>
		<mask id="svg-mask-avatar-default" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
		</mask>
		<mask id="svg-mask-avatar-status-round-16" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<circle fill="black" cx="0.8125" cy="0.8125" r="0.3125" />
		</mask>
		<mask id="svg-mask-avatar-status-mobile-16" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect fill="black" x="0.5" y="0.3125" width="0.625" height="0.8125" rx="0.1625" ry="0.1625" />
		</mask>
		<mask id="svg-mask-avatar-status-typing-16" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect fill="black" x="0.21875" y="0.5" width="1.1875" height="0.625" rx="0.3125" ry="0.3125" />
		</mask>
		<mask id="svg-mask-avatar-status-round-20" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<circle fill="black" cx="0.85" cy="0.85" r="0.25" />
		</mask>
		<mask id="svg-mask-avatar-status-mobile-20" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect fill="black" x="0.6" y="0.45" width="0.5" height="0.65" rx="0.13" ry="0.13" />
		</mask>
		<mask id="svg-mask-avatar-status-typing-20" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect fill="black" x="0.375" y="0.6" width="0.95" height="0.5" rx="0.25" ry="0.25" />
		</mask>
		<mask id="svg-mask-avatar-status-round-24" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<circle fill="black" cx="0.8333333333333334" cy="0.8333333333333334" r="0.2916666666666667" />
		</mask>
		<mask id="svg-mask-avatar-status-mobile-24" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect
				fill="black"
				x="0.5416666666666666"
				y="0.375"
				width="0.5833333333333334"
				height="0.75"
				rx="0.15"
				ry="0.15"
			/>
		</mask>
		<mask id="svg-mask-avatar-status-typing-24" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect
				fill="black"
				x="0.2916666666666667"
				y="0.5416666666666666"
				width="1.0833333333333333"
				height="0.5833333333333334"
				rx="0.2916666666666667"
				ry="0.2916666666666667"
			/>
		</mask>
		<mask id="svg-mask-avatar-status-round-32" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<circle fill="black" cx="0.84375" cy="0.84375" r="0.25" />
		</mask>
		<mask id="svg-mask-avatar-status-mobile-32" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect fill="black" x="0.59375" y="0.4375" width="0.5" height="0.65625" rx="0.13125" ry="0.13125" />
		</mask>
		<mask id="svg-mask-avatar-status-typing-32" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect fill="black" x="0.359375" y="0.59375" width="0.96875" height="0.5" rx="0.25" ry="0.25" />
		</mask>
		<mask id="svg-mask-avatar-status-round-40" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<circle fill="black" cx="0.85" cy="0.85" r="0.25" />
		</mask>
		<mask id="svg-mask-avatar-status-mobile-40" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect fill="black" x="0.6" y="0.45" width="0.5" height="0.65" rx="0.13" ry="0.13" />
		</mask>
		<mask id="svg-mask-avatar-status-typing-40" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect fill="black" x="0.375" y="0.6" width="0.95" height="0.5" rx="0.25" ry="0.25" />
		</mask>
		<mask id="svg-mask-avatar-status-round-48" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<circle fill="black" cx="0.875" cy="0.875" r="0.20833333333333334" />
		</mask>
		<mask id="svg-mask-avatar-status-mobile-48" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect
				fill="black"
				x="0.6666666666666666"
				y="0.5416666666666666"
				width="0.4166666666666667"
				height="0.5416666666666666"
				rx="0.10833333333333334"
				ry="0.10833333333333334"
			/>
		</mask>
		<mask id="svg-mask-avatar-status-typing-48" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect
				fill="black"
				x="0.4791666666666667"
				y="0.6666666666666666"
				width="0.7916666666666666"
				height="0.4166666666666667"
				rx="0.20833333333333334"
				ry="0.20833333333333334"
			/>
		</mask>
		<mask id="svg-mask-avatar-status-round-56" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<circle fill="black" cx="0.8392857142857143" cy="0.8392857142857143" r="0.19642857142857142" />
		</mask>
		<mask id="svg-mask-avatar-status-mobile-56" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect
				fill="black"
				x="0.6428571428571429"
				y="0.5178571428571429"
				width="0.39285714285714285"
				height="0.5178571428571429"
				rx="0.10357142857142858"
				ry="0.10357142857142858"
			/>
		</mask>
		<mask id="svg-mask-avatar-status-typing-56" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect
				fill="black"
				x="0.45535714285714285"
				y="0.6428571428571429"
				width="0.7678571428571429"
				height="0.39285714285714285"
				rx="0.19642857142857142"
				ry="0.19642857142857142"
			/>
		</mask>
		<mask id="svg-mask-avatar-status-round-80" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<circle fill="black" cx="0.85" cy="0.85" r="0.175" />
		</mask>
		<mask id="svg-mask-avatar-status-mobile-80" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect fill="black" x="0.675" y="0.575" width="0.35" height="0.45" rx="0.09" ry="0.09" />
		</mask>
		<mask id="svg-mask-avatar-status-typing-80" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect fill="black" x="0.525" y="0.675" width="0.65" height="0.35" rx="0.175" ry="0.175" />
		</mask>
		<mask id="svg-mask-avatar-status-round-120" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<circle fill="black" cx="0.8333333333333334" cy="0.8333333333333334" r="0.16666666666666666" />
		</mask>
		<mask id="svg-mask-avatar-status-mobile-120" maskContentUnits="objectBoundingBox" viewBox="0  0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect
				fill="black"
				x="0.6666666666666666"
				y="0.5666666666666667"
				width="0.3333333333333333"
				height="0.43333333333333335"
				rx="0.08666666666666667"
				ry="0.08666666666666667"
			/>
		</mask>
		<mask id="svg-mask-avatar-status-typing-120" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect
				fill="black"
				x="0.5166666666666667"
				y="0.6666666666666666"
				width="0.6333333333333333"
				height="0.3333333333333333"
				rx="0.16666666666666666"
				ry="0.16666666666666666"
			/>
		</mask>
		<mask id="svg-mask-status-online-mobile" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<rect fill="white" x={0} y={0} width={1} height={1} rx="0.1875" ry="0.125" />
			<rect fill="black" x="0.125" y="0.16666666666666666" width="0.75" height="0.5" />
			<ellipse fill="black" cx="0.5" cy="0.8333333333333334" rx="0.125" ry="0.08333333333333333" />
		</mask>
		<mask id="svg-mask-status-online" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
		</mask>
		<mask id="svg-mask-status-idle" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<circle fill="black" cx="0.25" cy="0.25" r="0.375" />
		</mask>
		<mask id="svg-mask-status-dnd" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<rect fill="black" x="0.125" y="0.375" width="0.75" height="0.25" rx="0.125" ry="0.125" />
		</mask>
		<mask id="svg-mask-status-offline" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<circle fill="black" cx="0.5" cy="0.5" r="0.25" />
		</mask>
		<mask id="svg-mask-status-streaming" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<circle fill="white" cx="0.5" cy="0.5" r="0.5" />
			<polygon fill="black" points="0.35,0.25 0.78301275,0.5 0.35,0.75" />
		</mask>
		<mask id="svg-mask-status-typing" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<rect fill="white" cx={0} cy={0} width={1} height={1} ry="0.5" rx="0.2" />
		</mask>
		<mask id="svg-mask-status-screenshare" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<rect width={1} height={1} fill="white" />
			<path d="M0.5 0.71875C0.5 0.649716 0.555966 0.59375 0.625 0.59375H1.0V1.0H0.5V0.71875Z" fill="black" />
		</mask>
		<mask id="svg-mask-avatar-decoration-status-round-16" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<rect fill="white" x="0" y="0" width="100%" height="100%" />
			<circle fill="black" cx="0.7604166666666667" cy="0.7604166666666667" r="0.2604166666666667" />
		</mask>
		<mask id="svg-mask-avatar-decoration-status-round-24" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<rect fill="white" x="0" y="0" width="100%" height="100%" />
			<circle fill="black" cx="0.7777777777777779" cy="0.7777777777777779" r="0.24305555555555558" />
		</mask>
		<mask id="svg-mask-avatar-decoration-status-round-32" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<rect fill="white" x="0" y="0" width="100%" height="100%" />
			<circle fill="black" cx="0.7864583333333334" cy="0.7864583333333334" r="0.20833333333333334" />
		</mask>
		<mask id="svg-mask-avatar-decoration-status-round-40" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<rect fill="white" x="0" y="0" width="100%" height="100%" />
			<circle fill="black" cx="0.7916666666666667" cy="0.7916666666666667" r="0.20833333333333334" />
		</mask>
		<mask id="svg-mask-avatar-decoration-status-round-48" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<rect fill="white" x="0" y="0" width="100%" height="100%" />
			<circle fill="black" cx="0.8125" cy="0.8125" r="0.17361111111111113" />
		</mask>
		<mask id="svg-mask-avatar-decoration-status-round-56" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<rect fill="white" x="0" y="0" width="100%" height="100%" />
			<circle fill="black" cx="0.7827380952380953" cy="0.7827380952380953" r="0.1636904761904762" />
		</mask>
		<mask id="svg-mask-avatar-decoration-status-round-80" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<rect fill="white" x="0" y="0" width="100%" height="100%" />
			<circle fill="black" cx="0.7916666666666667" cy="0.7916666666666667" r="0.14583333333333334" />
		</mask>
		<mask id="svg-mask-avatar-decoration-status-round-120" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
			<rect fill="white" x="0" y="0" width="100%" height="100%" />
			<circle fill="black" cx="0.7777777777777779" cy="0.7777777777777779" r="0.1388888888888889" />
		</mask>
	</svg>
);
