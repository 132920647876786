import {CheckCircle, XCircle} from 'lucide-react';
import {motion} from 'motion/react';
import {useCallback, useEffect} from 'react';
import type {ToastPropsExtended} from '~/components/uikit/Toast';

const MINIMUM_TIMEOUT = 1500;

export const Toast = ({
	id,
	type,
	children,
	timeout = MINIMUM_TIMEOUT,
	onClick,
	onTimeout,
	onClose,
	closeToast,
}: ToastPropsExtended) => {
	useEffect(() => {
		const finalTimeout = Math.max(timeout, MINIMUM_TIMEOUT);
		const timer = setTimeout(() => {
			if (onTimeout) onTimeout();
			else closeToast(id);
		}, finalTimeout);
		return () => clearTimeout(timer);
	}, [timeout, onTimeout, closeToast, id]);

	useEffect(() => {
		return () => {
			if (onClose) onClose();
		};
	}, [onClose]);

	const handleClick = useCallback(
		(event: React.MouseEvent) => {
			if (onClick) onClick(event);
			else closeToast(id);
		},
		[onClick, closeToast, id],
	);

	return (
		<motion.div
			onClick={handleClick}
			className="inline-flex items-center gap-3 px-5 py-3 bg-background-primary border-background-header-secondary rounded-full shadow-lg cursor-pointer max-w-[90vw]"
			initial={{opacity: 0, y: -30}}
			animate={{opacity: 1, y: 0}}
			exit={{opacity: 0, y: -30}}
			transition={{
				duration: 0.2,
				ease: 'easeOut',
			}}
		>
			{type === 'success' ? (
				<CheckCircle className="w-5 h-5 text-status-online flex-shrink-0" />
			) : (
				<XCircle className="w-5 h-5 text-status-danger flex-shrink-0" />
			)}
			<span className="text-text-primary text-base leading-snug font-medium">{children}</span>
		</motion.div>
	);
};
