import type {ToastProps} from '~/components/uikit/Toast';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';

type ToastEntry = {id: string; data: ToastProps};

type State = Readonly<{
	currentToast: ToastEntry | null;
	pendingToast: ToastEntry | null;
}>;

const initialState: State = {
	currentToast: null,
	pendingToast: null,
};

class ToastStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'TOAST_SET':
				return this.handleToastSet(action);
			case 'TOAST_DELETE':
				return this.handleToastDelete(action);
			case 'TOAST_ADVANCE_QUEUE':
				return this.handleAdvanceQueue();
			default:
				return false;
		}
	}

	private handleToastSet({id, data}: Readonly<{id: string; data: ToastProps}>): boolean {
		if (!id || !data) return false;

		this.setState((prevState) => {
			if (!prevState.currentToast) {
				return {
					...prevState,
					currentToast: {id, data},
				};
			}

			return {
				...prevState,
				pendingToast: {id, data},
			};
		});

		return true;
	}

	private handleToastDelete({id}: Readonly<{id: string}>): boolean {
		this.setState((prevState) => {
			if (prevState.currentToast?.id === id) {
				return {
					currentToast: prevState.pendingToast,
					pendingToast: null,
				};
			}

			if (prevState.pendingToast?.id === id) {
				return {
					...prevState,
					pendingToast: null,
				};
			}

			return prevState;
		});

		return true;
	}

	private handleAdvanceQueue(): boolean {
		this.setState((prevState) => ({
			currentToast: prevState.pendingToast,
			pendingToast: null,
		}));
		return true;
	}

	getCurrentToast() {
		return this.state.currentToast;
	}

	getPendingToast() {
		return this.state.pendingToast;
	}

	hasToast(id: string): boolean {
		return this.state.currentToast?.id === id || this.state.pendingToast?.id === id;
	}

	getToast(id: string): ToastProps | undefined {
		if (this.state.currentToast?.id === id) {
			return this.state.currentToast.data;
		}
		if (this.state.pendingToast?.id === id) {
			return this.state.pendingToast.data;
		}
		return undefined;
	}
}

export default new ToastStore();
