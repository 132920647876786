import {AnimatePresence} from 'motion/react';
import React from 'react';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import styles from '~/components/modals/Modals.module.css';
import ModalStore from '~/stores/ModalStore';

export const Modals = () => {
	const {modals} = ModalStore.useStore();

	const escFunction = React.useCallback((event: KeyboardEvent) => {
		if (event.key === 'Escape' && ModalStore.hasModalOpen()) {
			ModalActionCreators.pop();
		}
	}, []);

	React.useEffect(() => {
		document.addEventListener('keydown', escFunction);
		return () => {
			document.removeEventListener('keydown', escFunction);
		};
	}, [escFunction]);

	return (
		<div className={styles.modals}>
			<AnimatePresence>
				{modals.map(({key, modal: Modal, props}) => (
					<React.Fragment key={key}>
						<Modal {...props} />
					</React.Fragment>
				))}
			</AnimatePresence>
		</div>
	);
};
